import React from "react";
import UserBids from "../../user-bids";
import { Grid } from "react-bootstrap";
import { Helmet } from "react-helmet";

const UserBidsPage = props => {
	return (
		<>
			<Helmet>
				<title>Bids</title>
			</Helmet>

			<Grid>
				<UserBids {...props} />
			</Grid>
		</>
	);
};

export default UserBidsPage;
